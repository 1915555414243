.HomepageEventSectionTitle {
  color: #3d3d3d;
  width: 1024px;
  min-width: 1024px;
  font-size: 2rem;
  font-weight: 400;
}
.HomepageEventSectionTitle h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}
.HomepageEventSectionTitle p {
  font-size: 1.2rem;
  padding-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .HomepageEventSectionTitle {
    width: 85vw;
    min-width: 0px;
    font-size: 2rem;
  }
  .HomepageEventSectionTitle h1 {
    font-size: 3rem;
  }
  .HomepageEventSectionTitle p {
    font-size: 1rem;
    padding-bottom: 20px;
    text-align: justify;
  }
}