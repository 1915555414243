.BuildingRulesMap {
  width: 1024px;
  min-width: 1024px;
}
.BuildingRulesSectionHeading {
  width: 100%;
  border-bottom: 1px solid #fca26e;
  margin-top: 100px;
}
.BuildingRulesSectionMap {
  padding: 50px;
  border-bottom: 1px solid #fca26e;
}
@media screen and (max-width: 1024px) {
  .BuildingRulesMap {
    width: 85vw;
    min-width: 0px;
    margin-top: 50px;
  }
  .BuildingRulesSectionHeading {
    margin-top: 30px;
    font-size: 0.8rem;
  }
  .BuildingRulesSectionMap {
    padding: 30px 0 30px 0;
  }
}