.WhattodoPageMap {
  width: 1024px;
  min-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  display: flex;
  padding-top: 30px;
  color: var(--color-darkgrey);
}
.WhattodoPageMapContainer {
  display: flex;
  margin: auto;
}
.WhattodoPageMapHeading {
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
}
.WhattodoPageMapContent {
  position: relative;
  justify-content: center;
  display: flex;
  width: 600px;
  height: 600px;
}
.WhattodoPageMapContent img {
  width: 600px;
  height: 600px;
  object-fit: fill;
}
.AccommodationPageMapDS {
  height: 80px;
  width: 60px;
  position: absolute;
  bottom: 525px;
  left: 115px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapDS img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapDS img:hover {
  width: 70px;
}
.WhattodoPageEventButton input {
  border: 1px solid var(--color-lightorange);
  padding: 10px 30px;
  border-radius: 3px;
  color: var(--color-lightorange);
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  z-index: 999;
  background-color: transparent;
}
.WhattodoPageEventButton input:hover {
  background-color: var(--color-lightorange);
  color: #ffffff;
}
.WhattodoPageMapContainerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.WhattodoPageMapButtons {
  display: flex;
  justify-content: space-between;
  width: 400px;
}
