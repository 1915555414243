.EventsBridge {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    height: 150px;
    align-items: center;
    display: flex;
    color: #3d3d3d;
    min-width: 1104px;
}
.EventsBridgeContainer {
    width: 1024px;
    min-width: 1024px;
    margin: auto;
    display: flex;
    border-bottom: 1px solid #3d3d3d;
    height: 150px;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    .EventsBridge {
        height: 170px;
        min-width: 0px;
        width: 100vw;
        margin: auto;
    }
    .EventsBridgeContainer {
        min-width: 0px;
        height: 170px;
        width: 85vw;
    }
    .EventsBridgeContainer h1 {
        font-size: 3rem;
        margin: 0;
    }
}