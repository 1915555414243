.CommunityValuesIntroContainer {
  width: 1024px;
  min-width: 1024px;
  color: #ffffff;
}
.CommunityValuesIntroContainer h1 {
  font-size: 4.8rem;
  margin: 0;
}
.CommunityValuesIntroHeading {
  width: 100%;
  border-bottom: 1px solid #fca26e;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.CommunityValuesIntroContainer p {
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .CommunityValuesIntroContainer {
    width: 85vw;
    min-width: 0px;
  }
  .CommunityValuesIntroContainer h1 {
    font-size: 3rem;
  }
}
