.Header {
  display: flex;
  height: 80px;
  width: 100vw;
  min-width: 1104px;
  top: 0;
  justify-content: center;
  z-index: 999;
  position: absolute;
}
.HeaderContainerWrapper {
  width: 1024px;
  min-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Header img {
  height: 55px;
}
.HeaderNavigation ul {
  width: 600px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: large;
  list-style: none;
  color: #ffffff;
}
.HeaderNavigation li {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 5px;
}
.HeaderNavigation input {
  background-color: var(--color-lightorange);
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  z-index: 999;
}
.HeaderNavigation input:hover {
  background-color: #f2935c;
}
.HeaderLinkWrapper {
  padding-top: 10px;
}
.HeaderLink {
  text-decoration: none;
  color: #ffffff;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}
.HeaderLink:hover {
  border-bottom: 2px solid var(--color-lightorange);
}
.DropdownMenu {
  position: absolute;
  top: 100%;
  width: 180px;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 15px;
}

@media screen and (max-width: 1024px) {
  .Header {
  display: none;
  }
}