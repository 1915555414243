.HomepageEventSectionEventListMobile {
  width: 100vw;
  display: none;
  justify-content: center;
  height: 460px;
}
@media screen and (max-width: 1024px) {
  .HomepageEventSectionEventListMobile {
    display: flex;
  }
}