.RealEstateInvest {
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RealEstateInvestContentWrapper {
  width: 1024px;
  height: auto;
  position: relative;
}
.RealEstateInvestContentWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%; /* Position it just outside the container */
  width: calc(
    (100vw - 1024px) / 2
  ); /* Calculate the width to cover the remaining width of the viewport */
  height: 100%;
  background-color: var(--color-darkgreen); /* Set your desired border color */
}
.RealEstateInvestContentAddsIntro {
  margin: 0 20px 0 20px;
  color: #3d3d3d;
}
.RealEstateInvestContentAddsIntro p {
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .RealEstateInvest {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .RealEstateInvestContentAddsIntro {
    display: none;
  }
  .RealEstateInvestContentAddsWrapper {
    display: none;
  }
}
