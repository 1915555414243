.EventsList {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
    min-width: 1104px;
}
@media screen and (max-width: 1024px) {
    .EventsList {
        min-width: 0px;
        width: 100vw;
        align-items: center;
    }
}