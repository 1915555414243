.TowBarPage {
    background-color: var(--color-darkgreen);
    min-width: 1104px;
    display: flex;
    height: 100%;
}
@media screen and (max-width: 1024px) {
    .TowBarPage {
        min-width: 0px;
    }
}