body {
  margin: 0;
  font-family: 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-darkgreen: #182525;
  --color-lightgreen: #1F4034;
  --color-darkblue: #64817D;
  --color-lightblue: #CCDCDD;
  --color-lightorange: #FCA26E;
  --color-darkgorange: #F5751D;
  --color-darkgrey: #3D3D3D;
  --color-mediumgrey: #666666;
  --color-lightgrey: #BEBEBE;
}