.WhattodoPageActivities {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    height: 640px;
    position: relative;
    display: flex;
    margin-top: 150px;
}
.WhattodoPageActivityBackgroundImage {
    width: 1024px;
    height: 640px;
    object-fit: cover;
    border-radius: 4px;
}
.WhattodoPageActivityBackgroundImage img {
    width: 1024px;
    height: 640px;
    object-fit: cover;
    border-radius: 4px;
}
.WhattodoPageActivitiesTitle {
    color: #ffffff;
    position: absolute;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-size: 6.8rem;
    font-weight: 900;
    left: 50px;
    top: 105px;
}
.WhattodoPageActivitiesContentWrapper {
    position: absolute;
    height: 400px;
    width: 970px;
    left: 27px;
    top: 200px;
    background-color: rgba(220, 220, 220, 0.3);
    backdrop-filter: blur(3px);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}
.WhattodoPageActivitiesContent {
    border-radius: 4px;
    position: relative;
    background-color: #ffffff;
}
.WhattodoPageActivitiesContent img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    margin: 10px;
    border-radius: 4px;
    box-shadow: 2px 5px 10px rgba(24, 37, 37, 0.5);
}
.WhattodoPageActivitiesContentText {
    height: 150px;
    width: 150px;
    margin: 10px;
    position: relative;
    display: flex;
  flex-direction: column;
}
.WhattodoPageActivitiesContentText h3 {
    color: var(--color-darkgrey);
    font-style: italic;
    margin: 0;
}
.WhattodoPageActivitiesContentText p {
    font-size: 0.8rem;
    text-align: justify;
}
.WhattodoPageActivitiesContentLink {
    width: 30px;
    height: 30px;
    background-color: var(--color-lightorange);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
    box-shadow: 2px 3px 5px rgba(24, 37, 37, 0.3);
}
.WhattodoPageActivitiesContentLink img {
    height: 18px;
    width: 10px;
    object-fit: contain;
    box-shadow: none;
}
.WhattodoPageActivitiesContentTextParagraph {
    overflow: scroll;
    height: 125px;
}
@media screen and (max-width: 1024px) {
    .WhattodoPageActivities {
      display: none;
    }
  }