.AccommodationPageItemMobile {
  width: 300px;
  height: 400px;
  border-radius: 4px;
  position: relative;
  margin: auto;
  padding: 0;
  display: none;
  padding-bottom: 80px;
}
.AccommodationPageItemMobile img {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 4px;
  z-index: 0;
  box-shadow: 5px 10px 25px rgba(24, 37, 37, 0.5);
  margin: 0;
  padding: 0;
}
.AccommodationPageItemMobileInfoContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #ffffff;
  backdrop-filter: blur(5px);
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AccommodationPageItemMobileInfoContainer h3 {
  margin: 10px 0 0 0;
  padding: 0;
  font-weight: 400;

}
.AccommodationPageItemMobileInfoContainer h1 {
  margin: 0 0 10px 0;
  padding: 0;
}
.AccommodotionPageItemMobileLinkContainer {
  color: #ffffff;
  position: absolute;
  bottom: 80px;
  width: 300px;
  height: 60px;
  cursor: pointer;
  background-color: var(--color-lightorange);
  border-radius: 0 0 4px 4px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AccommodotionPageItemMobileLinkContainer h3 {
  margin: 0 0 0 20px;
}
img.AccommodotionPageItemMobileLinkContainerArrow {
  height: 20px;
  width: 12px;
  margin: 0 25px 0 0;
}
@media screen and (max-width: 1024px) {
  .AccommodationPageItemMobile {
    display: flex;
  }
}
