.BuildingRulesContainer {
  width: 1024px;
  min-width: 1024px;
}
.BuildingRulesContainer ul {
  list-style-type: none;
}
.BuildingRulesContainer h1 {
  font-size: 4.8rem;
  margin: 0;
}
.BuildingRulesContainerList {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.BuildingRulesContainerListItem {
  background-color: #fca26e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  margin-right: 10px;
}
.BuildingRulesContainer p {
  text-align: justify;
}
.BuildingRulesSectionParagraphImportant {
  font-style: italic;
  display: flex;
}
@media screen and (max-width: 1024px) {
  .BuildingRulesContainer {
    width: 85vw;
    min-width: 0px;
  }
  .BuildingRulesContainer h1 {
    font-size: 3rem;
  }
  .BuildingRulesContainer ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
  }
}
