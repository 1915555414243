.RealEstateIntro {
  width: 100vw;
  min-width: 1024px;
  height: 100vh;
  min-height: 800px;
  z-index: -1;
  background-color: var(--color-darkgreen);
  display: flex;
  justify-content: center;
  align-items: center;
}
.RealEstateIntroMap {
  width: 1024px;
  margin: auto;
  padding-bottom: 60px;
  z-index: 1;
}
.RealEstateIntroContent {
  width: 1024px;
  height: 600px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: absolute;
  z-index: 2;
}
.RealEstateIntroContentHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.RealEstateIntroContent h1 {
  font-size: 4rem;
  margin: 0;
}
.RealEstateIntroContent h3 {
  font-size: 1.1rem;
  font-weight: 500;
  width: 480px;
  font-style: italic;
  text-align: end;
}
.RealEstateIntroContentText {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .RealEstateIntro {
    min-width: 0px;
    min-height: 0px;
  }
  .RealEstateIntroMap {
    width: 90vw;
    margin: auto;
  }
  .RealEstateIntroContent {
    width: 80vw;
    height: 70vh;
  }
  .RealEstateIntroContent h1 {
    font-size: 2rem;
  }
  .RealEstateIntroContent h3 {
    font-size: 0.8rem;
    width: 300px;
  }
}
