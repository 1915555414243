.UserLoginFormWrapper {
  height: 500px;
  width: 500px;
  background-color: rgba(81, 85, 76, 0.4);
  border-radius: 5px;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.UserLoginTitle {
  color: #ffffff;
  font-weight: 200;
  margin-bottom: 0;
}
.UserLoginText {
  color: #ffffff;
  font-weight: 200;
  margin-bottom: 40px;
}
.UserLoginForm {
  width: 300px;
}
.UserLoginFormUser {
  border-bottom: 1px solid #fca26e;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.UserLoginFormPassword {
  border-bottom: 1px solid #fca26e;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.UserLoginFormUser img {
  width: 20px;
  padding-right: 10px;
  padding-left: 5px;
}
.UserLoginFormPassword img {
  width: 20px;
  padding-right: 10px;
  padding-left: 5px;
}
.UserLoginFormUser input {
  width: 100%;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  outline: none;
  caret-color: #fca26e;
  font-size: 1.2rem;
  color: #ffffff;
}
.UserLoginFormPassword input {
  width: 100%;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  outline: none;
  caret-color: #fca26e;
  caret-color: #fca26e;
  font-size: 1.2rem;
  color: #ffffff;
}
.UserLoginButton {
  background-color: #fca26e;
  border: 2px solid #fca26e;
  width: 200px;
  padding: 8px 28px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  margin-top: 60px;
}
.UserLoginButton:hover {
  background-color: #f2935c;
  border: 2px solid #f2935c;
}
.UserRegistrationButton {
  border: 2px solid #fca26e;
  width: 200px;
  padding: 8px 28px;
  border-radius: 3px;
  color: #fca26e;
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  margin-top: 20px;
  background-color: transparent;
}
.UserRegistrationButton:hover {
  background-color: #f2935c;
  border: 2px solid #f2935c;
  color: #ffffff;
}
.UserLoginErrorMessage {
  color: #b80f0a;
  font-size: 0.8rem;
  text-align: end;
  font-style: italic;
}
@media screen and (max-width: 1024px) {
  .UserLoginFormWrapper {
    width: 90vw;
    height: 420px;
  }
  .UserLoginText {
    margin-bottom: 10px;
  }
  .UserLoginTitle {
    margin-top: 0;
  }
  .UserLoginButton {
    margin-top: 20px;
  }
  .UserLoginForm {
    width: 250px;
  }
}
