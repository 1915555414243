.HomepageEventSectionButton {
  display: flex;
  justify-content: flex-end;
  width: 1024px;
  margin-top: 70px;
}
.HomepageEventSectionButton input {
  background-color: var(--color-lightorange);
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  z-index: 999;
}
.HomepageEventSectionButton input:hover {
  background-color: #f2935c;
}
@media screen and (max-width: 1024px) {
  .HomepageEventSectionButton {
    width: 85vw;
    margin-top: 10px;
  }
}