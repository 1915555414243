.WhattodoPageMadeMobileWrapper {
  background-color: var(--color-darkgreen);
  padding-top: 50px;
  padding-bottom: 1px;
}
.WhattodoPageMadeMobile {
  display: none;
  width: 300px;
  height: 500px;
  border-radius: 4px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  background: #ffffff;
  margin-bottom: 50px;
}
.WhattodoPageMadeMobile img {
  width: 280px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  z-index: 0;
  box-shadow: 5px 10px 15px rgba(24, 37, 37, 0.5);
  margin: 10px auto 0 auto;
  padding: 0;
}
.WhattodoPageMadeMobileContentLink {
  width: 40px;
  height: 40px;
  background-color: var(--color-lightorange);
  border-radius: 50%;
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  box-shadow: 2px 3px 5px rgba(24, 37, 37, 0.3);
}
.WhattodoPageMadeMobileContentLink img {
  height: 25px;
  width: 10px;
  object-fit: contain;
  box-shadow: none;
  margin: auto;
}
.WhattodoPageMadeMobile h2 {
  color: var(--color-darkgrey);
  font-style: italic;
  margin-left: 10px;
  margin-bottom: 0;
  max-width: 280px;
}
.WhattodoPageMadeMobileContentTextParagraph {
    overflow: scroll;
  height: 200px;
  width: 280px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageMadeMobile {
    display: flex;
    flex-direction: column;
  }
}
