.WhattodoPageNature {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 640px;
  position: relative;
  display: flex;
  margin-top: 150px;
}
.WhattodoPageNatureBackgroundImage {
  width: 1024px;
  height: 640px;
  object-fit: cover;
  border-radius: 4px;
}
.WhattodoPageNatureBackgroundImage img {
  width: 1024px;
  height: 640px;
  object-fit: cover;
  border-radius: 4px;
}
.WhattodoPageNatureTitle {
  color: #ffffff;
  position: absolute;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 6.8rem;
  font-weight: 900;
  right: 27px;
  top: 105px;
}
.WhattodoPageNatureContentWrapper {
  position: absolute;
  height: 400px;
  width: 780px;
  left: 27px;
  top: 200px;
  background-color: rgba(220, 220, 220, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.WhattodoPageNatureContent {
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
}
.WhattodoPageNatureContent img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(24, 37, 37, 0.5);
}
.WhattodoPageNatureContentText {
  height: 150px;
  width: 150px;
  margin: 10px;
  position: relative;
}
.WhattodoPageNatureContentText h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  margin: 0;
}
.WhattodoPageNatureContentText p {
  font-size: 0.8rem;
  text-align: justify;
}
.WhattodoPageNatureContentTextParagraph {
  overflow: scroll;
  height: 125px;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageNature {
    display: none;
  }
}
