.HeaderMobile {
  height: 64px;
  width: 100vw;
  background-color: var(--color-darkgreen);
  align-items: center;
  display: none;
  position: fixed;
  z-index: 999;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.7);
  justify-content: space-between;
}
.HeaderMobileLogo {
    margin-left: 20px;
}
.HeaderMobileLogo img {
  height: 40px;
}
.HeaderMobileButtons {
    display: flex;
    margin-right: 20px;
}
.HeaderMobileButtons img {
    height: 30px;
    margin-right: 25px;
  }
.HeaderMobileHamburgerMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.HeaderMobileHamburgerMenuLine {
    width: 30px;
    height: 3px;
    border-radius: 1.5px;
    background-color: #ffffff;
    margin: 4px 0;
    transition: 0.3s;
}

/* Animation for the hamburger menu */
.HeaderMobileHamburgerMenu.active .HeaderMobileHamburgerMenuLine:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 10px);
}

.HeaderMobileHamburgerMenu.active .HeaderMobileHamburgerMenuLine:nth-child(2) {
    opacity: 0;
}

.HeaderMobileHamburgerMenu.active .HeaderMobileHamburgerMenuLine:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -11px);
}
.HeaderMobileMenu {
    height: 0;
    overflow: hidden;
    transition: height 1s ease-in-out;
    display: flex;
    background-color: var(--color-darkgreen);
    position: absolute;
    top: 64px;
    width: 100vw;
    justify-content: end;
    overflow: scroll;
}
.HeaderMobileMenu.open {
    height: 400px;
  }
.HeaderMobileMenu ul {
    list-style: none;
    padding: 0;
    width: 100%;
}
.HeaderMobileMenu li {
    margin: 15px  15px 15px 15px;
    height: 35px;
    text-align: end;
    border-bottom: 1px solid var(--color-lightorange);
}
.HeaderMobileLink {
    text-decoration: none;
    color: #ffffff;
    padding-right: 5px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}
@media screen and (max-width: 1024px) {
  .HeaderMobile {
    display: flex;
  }
}
