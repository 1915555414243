.HomepageEventSectionEventItem {
  width: 300px;
  height: 400px;
  border-radius: 4px;
  position: relative;
  margin: 0;
  padding: 0;
}
.HomepageEventSectionEventItem img {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 4px;
  z-index: -1;
  box-shadow: 5px 10px 25px rgba(24, 37, 37, 0.5);
  margin: 0;
  padding: 0;
}
.HomepageEventSectionEventsItemDateContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #ffffff;
  background-color: var(--color-lightorange);
  border-radius: 4px 0 4px 0;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomepageEventSectionEventsItemDateContainer h3 {
  margin: 10px 0 0 0;
  padding: 0;
}
.HomepageEventSectionEventsItemDateContainer h1 {
  margin: 0 0 10px 0;
  padding: 0;
}
.HomepageEventSectionEventsItemTitleContainer {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 300px;
  height: 60px;
  cursor: pointer;
  backdrop-filter: blur(5px);
  border-radius: 0 0 4px 4px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HomepageEventSectionEventsItemTitleContainer h3 {
  margin: 0 0 0 20px;
}
img.HomepageEventSectionEventsItemTitleContainerArrow {
  height: 20px;
  width: 12px;
  margin: 0 25px 0 0;
}
@media screen and (max-width: 1024px) {
  .HomepageEventSectionEventItem {
    margin: auto;
  }
}
