.Loginpage_Hero {
  width: 100%;
  min-width: 1104px;
  height: 100%;
  min-height: 800px;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  justify-content: center;
}
.Loginpage_Hero_Layer {
  width: 100%;
  min-width: 1104px;
  height: 100vh;
  min-height: 800px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(00, 00, 00, 0.3);
}
.Login {
  height: 100vh;
  min-height: 800px;
  min-width: 1104px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .Loginpage_Hero {
    min-width: 0px;
    min-height: 0px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .Loginpage_Hero_Layer {
    width: 100vw;
    min-width: 0px;
    height: 100vh;
    min-height: 0px;
  }
  .Login {
    min-height: 0px;
    min-width: 0px;
  }
}
@media screen and (max-device-width: 1024px) and (orientation : landscape){
  .Loginpage_Hero {
    min-height: 660px;
  }
  .Loginpage_Hero_Layer {
    min-height: 660px;
  }
  .Login {
    min-height: 660px;
  }
}