.EventsIntro_Hero {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  justify-content: center;
  min-width: 1104px;
}
.EventsIntro_Hero_Layer {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(00, 00, 00, 0.4);
  display: flex;
  min-width: 1104px;
  position: fixed;
}
.EventsIntroWrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  min-width: 1104px;
}
.EventsIntro {
  height: 450px;
  justify-content: center;
  color: #ffffff;
  width: 1024px;
  margin: auto;
  min-width: 1024px;
}
.EventsIntro h1 {
  font-size: 4.8rem;
  margin: 0;
}
.EventsIntro h3 {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .EventsIntro_Hero {
    width: 100vw;
    min-height: 0px;
    min-width: 0px;
  }
  .EventsIntro_Hero_Layer {
    width: 100vw;
    min-height: 0px;
    min-width: 0px;
  }
  .EventsIntroWrapper {
    width: 100vw;
    height: calc(100vh - 64px);
    min-height: 0px;
    min-width: 0px;
    padding-top: 64px;
  }
  .EventsIntro {
    height: 75vh;
    width: 85vw;
    min-width: 0px;
  }
  .EventsIntro h1 {
    font-size: 3rem;
    margin: 0;
    padding-bottom: 10px;
  }
  .EventsIntro h3 {
    font-size: 1.1rem;
  }
  .EventsIntroContent {
    height: 80%;
  }
  .EventsIntroContentText {
    overflow: scroll;
    height: 100%;
  }
  .EventsIntroContentBorderTop {
    height: 10px;
    position: fixed;
  }
  .EventsIntroContentBorderBottom {
    height: 10px;
    position: fixed;
  }
}
@media screen and (max-device-width: 1024px) and (orientation : landscape){
  .EventsIntro_Hero {
    min-height: 660px;
  }
  .EventsIntro_Hero_Layer {
    min-height: 660px;
  }
  .EventsIntroWrapper {
    min-height: calc(460px - 64px);
  }
}
