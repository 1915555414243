.ActivityPage {
    min-width: 1024px;
    background-color: var(--color-darkgreen);
    padding-top: calc((100vh - 640px)/2);
}
@media screen and (max-width: 1024px) {
    .ActivityPage {
      min-width: 0px;
      padding-top: 0;
      background-color: transparent;
    }
  }