.RealEstateHouseItemModal {
  max-height: 0;
  width: 1014px;
  display: flex;
  position: relative;
  transition: height 1s linear;
  overflow: hidden;
  background-color: #fbfbfb;
  margin: 20px 10px 0 10px;
  border-radius: 4px;
}
.RealEstateHouseItemModal.open {
  max-height: 300px;
}
.RealEstateHouseItemModalCloseIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 4px;
}
.RealEstateHouseItemModalCloseIcon img {
  height: 18px;
}
.RealEstateHouseItemModalWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  width: 1000px;
  padding: 20px;
  box-sizing: border-box;
}
.RealEstateHouseItemModalContactPhoneWrapper {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;
}
.RealEstateHouseItemModalWrapper h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  font-weight: 600;
  margin-left: -10px;
}
.RealEstateHouseItemModalWrapper p {
  color: var(--color-mediumgrey);
  font-weight: 500;
}
.RealEstateHouseItemModalPhoneIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}
.RealEstateHouseItemModalPhoneIcon img {
  height: 18px;
}
.RealEstateHouseItemModalMailIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}
.RealEstateHouseItemModalMailIcon img {
  height: 18px;
  width: 18px;
}
@media screen and (max-width: 1024px) {
  .RealEstateHouseItemModal {
    width: 300px;
    margin: 0 auto 50px auto;
    padding-left: 15px;
    box-sizing: border-box;
  }
  .RealEstateHouseItemModal.open {
    max-height: 600px;
  }
  .RealEstateHouseItemModalWrapper {
    grid-template-columns: 1fr;
  }
}