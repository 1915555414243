.BuildingRules {
  width: 1024px;
  color: #ffffff;
  min-width: 1024px;
}
.BuildingRules p {
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .BuildingRules {
    width: 85vw;
    min-width: 0px;
  }
}