.WhattodoPageHistory {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 830px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 150px;
}
.WhattodoPageHistoryBackgroundImage {
  position: absolute;
  left: 0;
}
.WhattodoPageHistoryBackgroundImage img {
  width: 1024px;
  height: 830px;
  object-fit: cover;
  border-radius: 4px;
}
.WhattodoPageHistoryTitle {
  color: #ffffff;
  position: absolute;
  text-transform: uppercase;
  transform: rotate(270deg);
  translate: -40% 270%;
  margin: 0;
  padding: 0;
  font-size: 6.8rem;
  font-weight: 900;
  left: 110px;
  top: 0;
}
.WhattodoPageHistoryContentWrapper {
  position: absolute;
  height: 780px;
  width: 780px;
  right: 27px;
  background-color: rgba(220, 220, 220, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.WhattodoPageHistoryContent {
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
}
.WhattodoPageHistoryContent:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 5;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(3) {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(4) {
  grid-column-start: 3;
  grid-column-end: 5;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(6) {
  grid-column-start: 3;
  grid-column-end: 5;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.WhattodoPageHistoryContent:nth-child(8) {
  grid-column-start: 3;
  grid-column-end: 5;
  display: flex;
}
.WhattodoPageHistoryContent img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(24, 37, 37, 0.5);
}
.WhattodoPageHistoryContentText {
  height: 150px;
  width: 150px;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.WhattodoPageHistoryContentText h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  margin: 0;
}
.WhattodoPageHistoryContentTextParagraph {
  overflow: scroll;
  height: auto;
}
.WhattodoPageHistoryContentText p {
  font-size: 0.8rem;
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageHistory {
    display: none;
  }
}
