.RealEstateHouse {
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.RealEstateHouseContentWrapper {
    width: 1024px;
    position: relative;
}
.RealEstateHouseContentWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%; /* Position it just outside the container */
    width: calc((100vw - 1024px)/2); /* Calculate the width to cover the remaining width of the viewport */
    height: 100%;
    background-color: var(--color-darkgreen); /* Set your desired border color */
  }
  .RealEstateHouseContentAddsIntro {
    margin: 0 20px 0 20px;
  }
  .RealEstateHouseContentAddsIntro h2 {
    color: #3d3d3d;
  }
  .RealEstateHouseContentAddsIntro p {
    text-align: justify;
    color: #3d3d3d;
  }
  @media screen and (max-width: 1024px) {
    .RealEstateHouse {
      margin-top: 0px;
      margin-bottom: 0px;
  }
    .RealEstateHouseContentAddsWrapper {
      display: none;
    }
  }