.RealEstateHouseItem {
  border-left: 2px solid var(--color-lightorange);
  height: 350px;
  position: relative;
  padding: 15px;
  padding-bottom: 0;
  cursor: pointer;
  background-color: #fbfbfb;
  margin: 0 10px;
  border-radius: 4px;
}
.RealEstateHouseItemPrice {
  height: 40px;
  color: #ffffff;
  background-color: var(--color-lightorange);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 120px;
  border-radius: 4px 0 4px 0;
}
.RealEstateHouseItemArea {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 120px;
}
.RealEstateHouseItemAreaTextStyle {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-style: normal;
  color: var(--color-lightgrey);
}
.RealEstateHouseItemTextStyle {
  padding: 0.8rem 0 0 0;
  margin: 0;
  font-style: italic;
  color: var(--color-lightgrey);
}
.RealEstateHouseItem h2 {
  padding: 0;
  margin: 0;
  color: #3d3d3d;
}
.RealEstateHouseItemPrice h2 {
  padding: 0;
  margin: 0;
  font-style: normal;
  color: #ffffff;
}
.RealEstateHouseItemImage {
  width: 206px;
  height: 130px;
  margin-top: 15px;
}
.RealEstateHouseItemImage img {
  width: 206px;
  height: 130px;
  object-fit: cover;
}