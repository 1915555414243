.AccommodationPageList {
    width: 1024px;
    min-width: 1024px;
    margin: auto;
}
@media screen and (max-width: 1024px) {
    .AccommodationPageList {
        width: 100vw;
        min-width: 0px;
    }
  }