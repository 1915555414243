.AccommodationPageItemContainer {
  padding-top: 70px;
  padding-bottom: 70px;
  width: 1024px;
  min-width: 1024px;
  height: 400px;
  margin: auto;
  display: flex;
  color: #ffffff;
}
.AccommodationPageItemImageContainer {
  width: 320px;
  padding-right: 60px;
  display: flex;
  justify-content: center;
}
.AccommodationPageItemIcons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.AccommodationPageItemIcons div {
  display: flex;
  justify-content: center;
}
.AccommodationPageItemIcons img {
  height: 40px;
}
.AccommodationPageItemContentContainer {
  width: 644px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.AccommodationPageItemContainer h2 {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 2rem;
}
.AccommodationPageItemContainer p {
  margin: 0;
  text-align: justify;
  font-size: 1.2rem;
  padding-bottom: 10px;
}
.AccommodationPageItemImageContainer img {
  height: 100%;
  width: 320px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.5);
}
.AccommodationPageItemContentContainerButton {
  height: 40px;
  align-items: center;
  display: flex;
}
.AccommodationPageItemContentContainerButton button {
  background-color: var(--color-lightorange);
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  z-index: 999;
}
.AccommodationPageItemContentContainerButton button:hover {
  background-color: #f2935c;
}
@media screen and (max-width: 1024px) {
  .AccommodationPageItemContainer {
    display: none;
  }
}
