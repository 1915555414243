.BuildingPage {
    height: 100%;
    background-color: #182525;
    display: flex;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 100px;
    min-width: 1104px;
}
@media screen and (max-width: 1024px) {
    .BuildingPage {
      min-width: 0px;
    }
  }