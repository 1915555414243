.HomepageHome {
  height: 100vh;
  min-height: 800px;
  width: 1024px;
  display: flex;
  margin: auto;
  position: relative;
}
.HomepageHome_Hero_Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  justify-content: center;
}
.HomepageHome_Hero_Layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(00, 00, 00, 0.3);
}
.HomepageHomeAccentSquirrelContainer {
  position: absolute;
  bottom: -125px;
  left: 350px;
  width: 475px;
  height: 250px;
  box-shadow: 10px 15px 25px #182525;
  border-radius: 4px;
  z-index: 1;
}
.HomepageHomeAccentSquirrel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.HomepageHomeAccentWoodpeckerContainer {
  position: absolute;
  bottom: -125px;
  left: 875px;
  width: 475px;
  height: 250px;
  box-shadow: 10px 15px 25px #182525;
  border-radius: 4px;
  z-index: 1;
}
.HomepageHomeAccentWoodpecker {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

@media screen and (max-width: 1024px) {
  .HomepageHome {
    min-height: 0px;
    width: 100vw;
    min-height: 0px;
  }
  .HomepageHome_Hero_Image {
    min-width: 0px;
    min-height: 660px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .HomepageHome_Hero_Layer {
    width: 100vw;
    min-width: 0px;
    height: 100vh;
    min-height: 0px;
  }
  .HomepageHomeAccentSquirrelContainer {
    display: none;
  }
  .HomepageHomeAccentWoodpeckerContainer {
    display: none;
  }
}
@media screen and (max-device-width: 1024px) and (orientation : landscape){
  .HomepageHome {
    min-height: 660px;
  }
}