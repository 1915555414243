.HomepageIntro {
  background-color: #ffffff;
  display: flex;
  padding-top: 170px;
  padding-bottom: 70px;
}
.HomepageIntroContainer {
  width: 1024px;
  min-width: 1024px;
  height: 600px;
  position: relative;
  margin: auto;
  color: #ffffff;
  display: flex;
  color: #3d3d3d;
}
.HomepageIntroContainerLeft {
  width: 404px;
  padding-right: 20px;
}
.HomepageIntroContainerMiddle {
  width: 570px;
}
.HomepageIntroContainerRight {
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.HomepageIntroContainerLeftUpper {
  display: flex;
  margin-bottom: 30px;
}
.HomepageIntroContainerLeftUpper img {
  height: 32px;
  margin-right: 10px;
  z-index: 0;
}
.HomepageIntroContainerLeftUpper h3 {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
  padding: 0;
  margin-right: 30px;
}
.HomepageIntroContainer h1 {
  font-size: 4.8rem;
  margin: 0;
}
.HomepageIntroContainerLeft p {
  font-size: 1.5rem;
  font-weight: 100;
  text-align: justify;
}
.HomepageIntroContainerMiddle img {
  height: 600px;
}
@media screen and (max-width: 1024px) {
  .HomepageIntro {
    height: 100%;
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .HomepageIntroContainer {
    width: 85vw;
    min-width: 0px;
    flex-direction: column;
    height: 100%;
  }
  .HomepageIntroContainer h1 {
    font-size: 3rem;
  }
  .HomepageIntroContainerLeft {
    width: 85vw;
    padding-right: 0px;
  }
  .HomepageIntroContainerLeft p {
    font-size: 1rem;
  }
  .HomepageIntroContainerMiddle {
    width: 85vw;
  }
  .HomepageIntroContainerMiddle img {
    width: 85vw;
    height: 380px;
  }
  .HomepageIntroContainerRight {
    display: none;
  }
  .HomepageIntroContainerLeftUpper {
    align-items: center;
    margin-bottom: 10px;
  }
  .HomepageIntroContainerLeftUpper h3 {
    font-size: 1.5rem;
  }
}