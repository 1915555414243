.WhattodoPageIntroMobile {
  height: 40vh;
  display: none;
  padding-top: 64px;
  margin: 0;
  position: relative;
}
.WhattodoPageIntroMobile img {
  height: 40vh;
  margin: 0;
  z-index: -1;
  position: fixed;
}
.WhattodoPageIntroMobile_Hero_Layer {
  width: 100vw;
  position: fixed;
  top: 64px;
  height: 40vh;
  background-color: rgba(00, 00, 00, 0.3);
  z-index: -1;
}
.WhattodoPageIntroMobileContent {
  z-index: 2;
  color: #ffffff;
  width: 300px;
  margin: auto;
}
.WhattodoPageIntroMobileContent p {
  text-align: justify;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageIntroMobile {
    display: flex;
  }
}
