.RealEstateInvestItemMobile {
  border-left: 2px solid var(--color-lightorange);
  height: 450px;
  width: 300px;
  position: relative;
  padding: 15px;
  padding-bottom: 0;
  cursor: pointer;
  background-color: #fbfbfb;
  margin: 0 auto 50px auto;
  border-radius: 4px;
  display: none;
  box-sizing: border-box;
}
.RealEstateInvestItemMobilePrice {
  height: 40px;
  color: #ffffff;
  background-color: var(--color-lightorange);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 140px;
  border-radius: 4px 0 4px 0;
}
.RealEstateInvestItemMobileArea {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 120px;
}
.RealEstateInvestItemMobileAreaTextStyle {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-style: normal;
  color: var(--color-lightgrey);
}
.RealEstateInvestItemMobileTextStyle {
  padding: 0.8rem 0 0 0;
  margin: 0;
  font-style: italic;
  color: var(--color-lightgrey);
}
.RealEstateInvestItemMobile h2 {
  padding: 0;
  margin: 0;
  color: #3d3d3d;
}
.RealEstateInvestItemMobilePrice h2 {
  padding: 0;
  margin: 0;
  font-style: normal;
  color: #ffffff;
}
.RealEstateInvestItemMobileImage {
  width: 270px;
  height: 200px;
  margin-top: 15px;
}
.RealEstateInvestItemMobileImage img {
  width: 270px;
  height: 200px;
  object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .RealEstateInvestItemMobile {
    display: block;
  }
}
