.BuildingRulesSectionHeading__Allowed {
  width: 1024px;
  min-width: 1024px;
  border-bottom: 1px solid #fca26e;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.BuildingRulesSectionHeading__Allowed img {
  height: 20px;
  margin-right: 20px;
}
.BuildingRulesSectionParagraphWrapper__Allowed {
  border-bottom: 1px solid #fca26e;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s linear;
}
.BuildingRulesSectionParagraphWrapper__Allowed.visible {
  max-height: 1000px;
}
.BuildingRulesSectionParagraph {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 70px;
  row-gap: 10px;
  width: 90%;
  margin: auto;
}
.BuildingRulesSectionParagraphPart {
  display: flex;
  align-items: center;
}
.BuildingRulesSectionParagraph img {
  width: 40px;
  margin: 20px 10px;
  object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .BuildingRulesSectionHeading__Allowed {
    width: 85vw;
    min-width: 0px;
    font-size: 0.8rem;
  }
  .BuildingRulesSectionHeading__Allowed img {
    margin-right: 0px;
  }
  .BuildingRulesSectionParagraphWrapper__Allowed.visible {
    max-height: 2000px;
  }
  .BuildingRulesSectionParagraph {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    width: 85vw;
    margin: 0;
  }
  .BuildingRulesSectionParagraph p {
    font-size: 0.7rem;
  }
  .BuildingRulesSectionParagraphPart p {
    font-size: 0.7rem;
  }
  .BuildingRulesSectionParagraph img {
    margin: 20px 10px 20px 0;
  }
}