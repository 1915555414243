.RealEstateLand {
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RealEstateLandContentWrapper {
  width: 1024px;
  height: auto;
  position: relative;
}
.RealEstateLandContentWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%; /* Position it just outside the container */
  width: calc(
    (100vw - 1024px) / 2
  ); /* Calculate the width to cover the remaining width of the viewport */
  height: 100%;
  background-color: var(--color-darkgreen); /* Set your desired border color */
}
.RealEstateLandContentAddsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.RealEstateLandContentAddsIntro {
  margin-right: 20px;
  color: #3d3d3d;
}
.RealEstateLandContentAddsItems {
  grid-column-start: 2;
  grid-column-end: 5;
}
@media screen and (max-width: 1024px) {
  .RealEstateLand {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .RealEstateLandContentAddsWrapper {
    display: none;
  }
}
