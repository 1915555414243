.RealEstateLandItem {
    border-left: 2px solid var(--color-lightorange);
    height: 350px;
    position: relative;
    padding: 0px;
    padding-bottom: 0;
    cursor: pointer;
    background-color: #fbfbfb;
    margin: 0 10px;
    border-radius: 4px;
}
.RealEstateLandItem img {
    position: absolute;
    z-index: 0;
    height: 350px;
    padding: 0;
    margin: 0;
    object-fit: cover;
    top: 0;
    border-radius: 4px;
}
.RealEstateLandItemPrice {
    height: 40px;
    color: #ffffff;
    background-color: var(--color-lightorange);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 120px;
    border-radius: 4px 0 4px 0;
    z-index: 2;
}
.RealEstateLandItemArea {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 120px;
    z-index: 2;
}
.RealEstateLandItemAreaTextStyle {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-style: normal;
    color: var(--color-lightgrey);
    z-index: 2;
}
.RealEstateLandItemTextStyle {
    padding: 0.8rem 0 0 0;
    margin: 0;
    font-style: italic;
    color: var(--color-lightgrey);
    z-index: 2;
    position: absolute;
    top: 40px;
    left: 15px;
    width: 200px;
}
.RealEstateLandItem h2 {
    padding: 0;
    margin: 0;
    color: var(--color-darkgrey);
    z-index: 2;
    position: absolute;
    top: 15px;
    left: 15px;
}
.RealEstateLandItemPrice h2 {
    padding: 0;
    margin: 0;
    font-style: normal;
    color:#ffffff;
    z-index: 2;
    position: static;
}