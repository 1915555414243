.EventsSection {
  width: 100vw;
  min-width: 1104px;
  background-color: #ffffff;
  padding-top: 70px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .EventsSection {
    padding-top: 40px;
    padding-bottom: 40px;
    min-width: 0px;
    width: 100vw;
  }
}