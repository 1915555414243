.HomepageEventSectionEventList {
  width: 1024px;
  min-width: 1024px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .HomepageEventSectionEventList {
    display: none;
  }
}