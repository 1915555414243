.WhattodoPageFood {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 264px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 150px;
  padding-bottom: 150px;
}
.WhattodoPageFoodBackgroundImage {
  position: absolute;
  left: 0;
}
.WhattodoPageFoodBackgroundImage img {
  width: 1024px;
  height: 264px;
  object-fit: cover;
  border-radius: 4px;
}
.WhattodoPageFoodTitle {
  color: #ffffff;
  position: absolute;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 6.8rem;
  font-weight: 900;
  left: 123px;
  top: 10px;
}
.WhattodoPageFoodContentWrapper {
  position: absolute;
  height: 210px;
  width: 400px;
  right: 27px;
  background-color: rgba(220, 220, 220, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.WhattodoPageFoodContent {
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
}
.WhattodoPageFoodContent img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(24, 37, 37, 0.5);
}
.WhattodoPageFoodContentText {
  height: 150px;
  width: 150px;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.WhattodoPageFoodContentTextParagraph {
  overflow: scroll;
  height: 125px;
}
.WhattodoPageFoodContentText h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  margin: 0;
}
.WhattodoPageFoodContentText p {
  font-size: 0.8rem;
  text-align: justify;
}
.WhattodoPageFoodContentLink {
  width: 30px;
  height: 30px;
  background-color: var(--color-lightorange);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  box-shadow: 2px 3px 5px rgba(24, 37, 37, 0.3);
}
.WhattodoPageFoodContentLink img {
  height: 18px;
  width: 10px;
  object-fit: contain;
  box-shadow: none;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageFood {
    display: none;
  }
}
