.CommunityValuesStorm {
  width: 1024px;
  min-width: 1024px;
  margin-top: 70px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding-bottom: 70px;
  border-bottom: 2px solid #fca26e;
}
.CommunityValuesStorm p {
  font-style: italic;
  padding: 0;
}
.CommunityValuesStormHeading {
  border-bottom: 2px solid #fca26e;
}
.CommunityValuesStormHeading h2 {
  font-size: 3rem;
  margin: 0 0 20px 0;
}
.CommunityValuesStormImage {
  grid-column-start: 2;
  grid-column-end: 4;
}
.CommunityValuesStormImage img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
@media screen and (max-width: 1024px) {
  .CommunityValuesStorm {
    width: 85vw;
    min-width: 0px;
    margin-top: 50px;
    height:auto;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 20px;
  }
  .CommunityValuesStorm p {
    text-align: justify;
  }
  .CommunityValuesStormHeading h2 {
    font-size: 2rem;
  }
  .CommunityValuesStormImage {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .CommunityValuesStormImage img {
    height: 40vh;
  }
}
@media screen and (max-device-width: 1024px) and (orientation : landscape){
  .CommunityValuesStormImage img {
    height: 100%;
  }
}