.WhattodoPageMade {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 640px;
  position: relative;
  display: flex;
  align-items: center;
}
.WhattodoPageMadeBackgroundImage {
  position: absolute;
  left: 0;
}
.WhattodoPageMadeBackgroundImage img {
  width: 1024px;
  height: 640px;
  object-fit: cover;
  border-radius: 4px;
}
.WhattodoPageMadeContentWrapper {
  position: absolute;
  height: 590px;
  width: 780px;
  right: 27px;
  background-color: rgba(220, 220, 220, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.WhattodoPageMadeTitle {
  color: #ffffff;
  position: absolute;
  text-transform: uppercase;
  transform: rotate(270deg);
  translate: -40% 200%;
  margin: 0;
  padding: 0;
  font-size: 6.8rem;
  font-weight: 900;
  left: 123px;
  top: 0;
}
.WhattodoPageMadeContent {
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
}
.WhattodoPageMadeContent:nth-child(1) {
  grid-row-start: 1;
  grid-row-end: 3;
}
.WhattodoPageMadeContent:nth-child(2) {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.WhattodoPageMadeContent:nth-child(3) {
  grid-row-start: 1;
  grid-row-end: 3;
}
.WhattodoPageMadeContent:nth-child(4) {
  grid-column-start: 3;
  grid-column-end: 5;
  display: flex;
}
.WhattodoPageMadeContent:nth-child(5) {
  grid-row-start: 2;
  grid-row-end: 4;
}
.WhattodoPageMadeContent img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(24, 37, 37, 0.5);
}
.WhattodoPageMadeContentText {
  height: 150px;
  width: 150px;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.WhattodoPageMadeContentTextParagraph {
  overflow: scroll;
  height: 125px;
}
.WhattodoPageMadeContentText h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  margin: 0;
}
.WhattodoPageMadeContentText p {
  font-size: 0.8rem;
  text-align: justify;
}
.WhattodoPageMadeContentLink {
  width: 30px;
  height: 30px;
  background-color: var(--color-lightorange);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  box-shadow: 2px 3px 5px rgba(24, 37, 37, 0.3);
}
.WhattodoPageMadeContentLink img {
  height: 18px;
  width: 10px;
  object-fit: contain;
  box-shadow: none;
}
@media screen and (max-width: 1024px) {
  .WhattodoPageMade {
    display: none;
  }
}
