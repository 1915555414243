.CommunityValuesPage {
    height: 100%;
    min-width: 1104px;
    background-color: #182525;
    display: flex;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 100px;
}
@media screen and (max-width: 1024px) {
    .CommunityValuesPage {
        padding-top: 100px;
        min-width: 0px;
    }
}