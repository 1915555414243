.CommunityValuesSmart {
  width: 1024px;
  min-width: 1024px;
  margin-top: 90px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding-bottom: 70px;
  border-bottom: 2px solid #fca26e;
}
.CommunityValuesSmart p {
  font-style: italic;
}
.CommunityValuesSmartHeading {
  border-bottom: 2px solid #fca26e;
}
.CommunityValuesSmartHeading h2 {
  font-size: 3rem;
  margin: 0 0 20px 0;
}
.CommunityValuesSmartImage {
  grid-column-start: 1;
  grid-column-end: 3;
}
.CommunityValuesSmartImage img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
@media screen and (max-width: 1024px) {
  .CommunityValuesSmart {
    width: 85vw;
    min-width: 0px;
    margin-top: 50px;
    height:auto;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 20px;
  }
  .CommunityValuesSmart p {
    text-align: justify;
  }
  .CommunityValuesSmartHeading h2 {
    font-size: 2rem;
  }
  .CommunityValuesSmartImage {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .CommunityValuesSmartImage img {
    height: 40vh;
  }
}
@media screen and (max-device-width: 1024px) and (orientation : landscape){
  .CommunityValuesSmartImage img {
    height: 100%;
  }
}