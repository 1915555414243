.EventsItemContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 1024px;
    min-width: 1024px;
    height: 400px;
    margin: auto;
    display: flex;
    border-bottom: 2px solid #bdbdbd;
    color: #48494B;
}
.EventsItemDateContainer {
    width: 100px;
}
.EventsItemImageContainer {
    width: 320px;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    object-fit:fill;
}
.EventsItemContentContainer {
    width: 544px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.EventsItemContainer h1 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 3rem;
}
.EventsItemContainer h2 {
    margin: 0 0 20px 0;
    padding: 0;
}
.EventsItemContainer h3 {
    margin: 0;
    padding: 0;
}
.EventsItemContainer p {
    margin: 0;
    text-align: justify;
}
.EventsItemImageContainer img {
    height: 100%;
    width: 320px;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 5px 10px 25px rgba(24, 37, 37, 0.5);
}
.EventsItemContentContainerButton {
    border-top: 2px solid #bdbdbd;
    height: 60px;
    align-items: center;
    display: flex;
}
.EventsItemContentContainerButton button {
    padding: 10px 30px 10px 10px;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #48494B;
    justify-content: center;
    display: flex;
    font-family: 'Roboto Condensed';
}
.EventsItemContentContainerButton button img {
    height: 1rem;
    margin-left: 40px;
}
.EventsItemContentAddress {
    font-style: italic;
    padding-bottom: 5px;
}
.EventsItemContentStartTime {
    font-style: italic;
    padding-bottom: 15px;
}
@media screen and (max-width: 1024px) {
    .EventsItemContainer {
        display: none;
    }
}