.RealEstateLandItemModal {
  max-height: 0;
  width: 989px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
  margin: 20px 10px 0 10px;
  border-radius: 4px;
  padding-left: 15px;
  transition: max-height 1s linear;
}
.RealEstateLandItemModal.open {
  max-height: 300px;
}
.RealEstateLandItemModalWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  width: 1000px;
  padding: 20px;
  box-sizing: border-box;
}
.RealEstateLandItemModalCloseIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}
.RealEstateLandItemModalCloseIcon img {
  height: 18px;
}
.RealEstateLandItemModalContactPhoneWrapper {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;
}
.RealEstateLandItemModalWrapper h3 {
  color: var(--color-darkgrey);
  font-style: italic;
  font-weight: 600;
  margin-left: -10px;
}
.RealEstateLandItemModalWrapper p {
  color: var(--color-mediumgrey);
  font-weight: 500;
}
.RealEstateLandItemModalPhoneIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}
.RealEstateLandItemModalPhoneIcon img {
  height: 18px;
}
.RealEstateLandItemModalMailIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}
.RealEstateLandItemModalMailIcon img {
  height: 18px;
  width: 18px;
}
@media screen and (max-width: 1024px) {
  .RealEstateLandItemModal {
    width: 300px;
    margin: 0 auto 50px auto;
    padding-left: 15px;
    box-sizing: border-box;
  }
  .RealEstateLandItemModal.open {
    max-height: 600px;
  }
  .RealEstateLandItemModalWrapper {
    grid-template-columns: 1fr;
  }
}