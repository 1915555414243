.HomepageBlogTowBar {
  min-width: 1024px;
  width: 1024px;
  height: 600px;
  margin: auto;
  display: flex;
  position: relative;
}
.HomepageBlogTowBarImageFisher {
  width: 600px;
  height: 500px;
  object-fit: cover;
  z-index: 1;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
.HomepageBlogTowBarTextContainer {
  width: 700px;
  height: 500px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--color-lightgreen);
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
}
.HomepageBlogTowBarTextWrapper {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 420px;
  margin: 40px 40px 40px 10px;
  justify-content: space-between;
}
.HomepageBlogTowBarTextWrapper h2 {
    font-size: 2rem;
}
.HomepageBlogTowBarTextContainerLink {
  border-top: 2px solid #bdbdbd;
  height: auto;
}
.HomepageBlogTowBarTextContainerLinkArrow {
  height: 1rem;
  width: 1rem;
  margin-left: 40px;
}
.HomepageBlogTowBarLink {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .HomepageBlogTowBar {
    min-width: 0px;
    width: 85vw;
    height: 580px;
  }
  .HomepageBlogTowBarImageFisher {
    width: 85vw;
    height: 42%;
  }
  .HomepageBlogTowBarTextContainer {
    width: 85vw;
    height: 580px;
    justify-content: center;
    align-items: flex-end;
  }
  .HomepageBlogTowBarTextWrapper {
    width: 75vw;
    height: 58%;
    margin: 0;
    justify-content: space-between;
  }
}