.TowBarPageContent {
  width: 1024px;
  min-width: 1024px;
  color: #ffffff;
  padding-top: 150px;
  padding-bottom: 100px;
  margin: auto;
  text-align: justify;
}
.TowBarPageContent h1 {
  font-size: 4.8rem;
  margin: 0;
}
.TowBarContentContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.TowBarContentContainerBulletin {
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  margin-right: 10px;
}
.TowBarContentContainerBulletinCircle {
  background-color: #fca26e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1024px) {
  .TowBarPageContent {
    width: 85vw;
    min-width: 0px;
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .TowBarPageContentList {
    padding-left: 5px;
  }
  .TowBarPageContent h1 {
    font-size: 3rem;
  }
  .TowBarContentContainerBulletinCircle {
    background-color: var(--color-lightorange);
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}
