.Footer {
  background-color: #182525;
  height: 130px;
  display: flex;
  color: #fca26e;
  padding: 40px 0;
  justify-content: center;
  min-width: 1024px;
}
.FooterContainerWrapper {
  width: 1014px;
  margin-left: auto;
  margin-right: auto;
}
.FooterContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  justify-content: space-between;
}
.FooterContainer h4 {
  margin: 0 0 5px 0;
}
.FooterContainer ul {
  list-style: none;
  padding-inline-start: 0;
}
.FooterPagemapSection:first-of-type {
  margin-right: 40px;
}
.FooterPagemapSection:nth-of-type(2) {
  margin-right: 40px;
}
.FooterContainer ul li {
  font-size: 0.7rem;
  line-height: 1.5rem;
  display: flex;
}
.FooterId {
  display: flex;
}
.FooterPagemap {
  display: flex;
  justify-content: space-around;
}
.FooterIdLogoWrapper {
  width: 65px;
  margin-right: 20px;;
}
.FooterFollow img {
  height: 15px;
  max-width: 18px;
}
.FooterFollow div {
  width: 18px;
  margin-right: 5px;
}
.FooterLink {
    text-decoration: none;
    color: #fca26e;
}

@media screen and (max-width:1024px) {
  .Footer {
    height: 100%;
    padding: 50px 0 0 0;
    min-width: 100%;
    width: 100vw;
  }
  .FooterContainerWrapper{
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .FooterContainer {
    flex-direction: column;
    align-items: center;
  }
  .FooterPagemap {
    flex-direction: column;
  }
  .FooterPagemapSection {
    display: none;
  }
  .FooterPagemapSection:first-of-type {
    margin-right: 0px;
  }
  .FooterPagemapSection:nth-of-type(2) {
    margin-right: 0px;
  }
  li {
    justify-content: center;
  }
  .FooterId, .FooterPagemapSection, .FooterFollow, .FooterContact {
    margin-bottom: 30px;
  }
}