.BlogSection {
    height: 100vh;
    min-height: 800px;
    background-color: #182525;
    display: flex;
  }
  @media screen and (max-width: 1024px) {
    .BlogSection {
      height: 740px;
      min-height: 0px;
    }
  }