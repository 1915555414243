.HomepageHomeContainer {
  width: 1024px;
  min-width: 1024px;
  margin: auto;
  color: #ffffff;
}
.HomepageHomeContainerUpper {
  height: 360px;
  display: flex;
}
.HomepageHomeContainerUpperLeft {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.HomepageHomeContainerUpperLeftItem {
  background-color: #fca26e;
  width: 15px;
  height: 15px;
  margin-bottom: 15px;
  border-radius: 50%;
}
.HomepageHomeContainerUpperRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.HomepageHomeContainerUpperRight h1 {
  font-size: 6rem;
  margin: 0;
}
.HomepageHomeContainerUpperRight h2 {
  font-size: 2rem;
  font-weight: 100;
  margin: 3px;
}
.HomepageHomeContainerLower {
  height: 140px;
  display: flex;
}
.HomepageHomeContainerLowerLeft {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.HomepageHomeContainerLowerRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.HomepageHomeContainerLowerRight h3 {
  font-weight: 600;
  margin: 3px;
}
.HomepageHomeContainerLowerRight p {
  font-weight: 400;
  margin: 3px;
}
.LocationIcon {
  width: 35px;
}
@media screen and (max-width: 1024px) {
  .HomepageHomeContainer {
    width: 85vw;
    min-width: 0px;
  }
  .HomepageHomeContainerUpper {
    height: 260px;
  }
  .HomepageHomeContainerUpperLeft {
    display: none;
  }
  .HomepageHomeContainerUpperRight h1 {
    font-size: 3rem;
  }
  .HomepageHomeContainerUpperRight h2 {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .HomepageHomeContainerLowerLeft {
    width: 30px;
  }
  .LocationIcon {
    width: 20px;
    margin-bottom: 3px;
  }
  .HomepageHomeContainerLowerRight h3 {
    font-size: 0.8rem;
  }
  .HomepageHomeContainerLowerRight p {
    font-size: 0.8rem;
  }
}
