.AccommodationPageMap {
  width: 1024px;
  min-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  display: flex;
  padding-top: 30px;
  color: #ffffff;
}
.AccommodationPageMapContainer {
  display: flex;
  margin: auto;
}
.AccommodationPageMapHeading {
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
}
.AccommodationPageMapContent {
  position: relative;
  justify-content: center;
  display: flex;
}
.AccommodationPageMapContent img {
  width: 550px;
}
.AccommodationPageMapSS {
  height: 80px;
  width: 60px;
  position: absolute;
  top: 20px;
  left: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapSS img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapSS img:hover {
  width: 70px;
}
.AccommodationPageMapKS {
  height: 80px;
  width: 60px;
  position: absolute;
  top: -45px;
  left: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapKS img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapKS img:hover {
  width: 70px;
}
.AccommodationPageMapKD {
  height: 80px;
  width: 60px;
  position: absolute;
  bottom: 210px;
  left: 180px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapKD img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapKD img:hover {
  width: 70px;
}
.AccommodationPageMapDS {
  height: 80px;
  width: 60px;
  position: absolute;
  bottom: 525px;
  left: 115px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapDS img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapDS img:hover {
  width: 70px;
}
.AccommodationPageMapDP {
  height: 80px;
  width: 60px;
  position: absolute;
  bottom: 10px;
  left: 310px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.AccommodationPageMapDP img {
  width: 60px;
  object-fit: fill;
  transition: width 0.5s ease;
}
.AccommodationPageMapDP img:hover {
  width: 70px;
}
.AccommodationPageEventButton input {
  border: 1px solid var(--color-lightorange);
  padding: 10px 30px;
  border-radius: 3px;
  color: var(--color-lightorange);
  font-weight: 600;
  font-size: large;
  cursor: pointer;
  text-decoration: none;
  z-index: 999;
  background-color: transparent;
}
.AccommodationPageEventButton input:hover {
  background-color: var(--color-lightorange);
  color: #ffffff;
}
.AccommodationPageMapContainerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.AccommodationPageMapButtons {
  display: flex;
  justify-content: space-between;
  width: 350px;
}
@media screen and (max-width: 1024px) {
  .AccommodationPageMap {
    width: 85vw;
    min-width: 0px;
  }
  .AccommodationPageMapContainer {
    height: auto;
    margin: auto;
  }
  .AccommodationPageMapHeading {
    font-size: 3rem;
  }
  .AccommodationPageMapContent {
    display: none;
  }
  .AccommodationPageMapButtons {
    display: none;
  }
}
